  .switch-group {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  
  .button-group {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }

  
  .tag-preview {
    margin-top: 20px;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }
  
  .tag-preview-image {
    display: flex;
    max-width: 25px;
    max-height: 25px;
  }


  .image-upload {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 25px;
  }
  
  .image-upload input[type="file"] {
    display: none;
  }
  
  .image-upload label {
    cursor: pointer;
    padding: 10px 20px;
    background-color: var(--main-content-button-background-color);
    color: var(--main-content-button-text-color);
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  .image-upload img {
    max-height: 25px;
    max-width: 25px;
    border-radius: 5px;
    margin-top: 10px;
  }