.song_grid {
  grid-template-columns: 2fr 2fr 3fr 2fr;
  display: grid;
  gap: 10px;
  align-items: center;
  align-items: flex-start;
}

.events_toggle {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}

.contextMenuContainer {
  position: relative;
}

.contextMenu {
  position: absolute;
  top: 0;
  left: 100%; /* Position to the right of the button */
  background-color: var(--main-content-card-background-color);
  border: 1px solid #000;
  box-shadow: 0 2px 10px var(--shadow-color);
  border-radius: 4px;
  z-index: 1000;
  padding: 5px;
  width: 100px;
}

.contextMenuIcon {
  margin-right: 8px;
}

.contextMenu button {
  display: block;
  width: 100%;
  padding: 8px 12px;
  background: none;
  border: none;
  text-align: left;
  cursor: pointer;
  font-size: 14px;
  color: var(--main-content-text-color);
}

.contextMenu button:hover {
  background-color: var(--main-content-button-background-color);
  color: var(--main-nav-button-hover-text-color);
}