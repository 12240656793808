@font-face {
    font-family: 'Titan One';
    src: url('../fonts/TitanOne-Regular.ttf') format('truetype');
  }


/* Background and container styles */
body, html {
    margin: 0;
    padding: 0;
    font-family: 'Arial', sans-serif;
    background-color: var(--background);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .intro-page {
    text-align: center;
    color: var(--slogan-color);
    padding: 20px;
  }
  
  
  /* Slogan */
  .slogan {
    font-size: 24px;
    margin-bottom: 50px;
    display: block;
  }
  
 /* Login button */
 .login-button-container {
  border-radius: 30px;
 }


.login-button {
  color: var(--login-button-text-color);
  border: none;
  border-radius: 30px;
  padding: 0px 15px 0px 0px;
  background-color: var(--login-button-background-color);
  font-size: 20px;
  cursor: pointer;
  gap: 10px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 4px 6px var(--shadow-color);
}

.login-button:hover {
  background-color: var(--login-button-hover-background-color);
  color: var(--login-button-hover-text-color);
  transform: translateY(-2px);
}

.login-button:active {
  background-color: var(--login-button-active-background-color);
  color: var(--login-button-active-text-color);
  transform: translateY(0);
}

.login-icon-container {
  background-color: var(--login-button-hover-background-color);
  padding-right: 25px;
  padding-top: 10px;
  padding-bottom: 0px;
  padding-left: 25px;
  margin-top: 0em;
  border-radius: 30px;

  display: flex;
  float: left;
}

.login-icon {
  color: var(--logo-bottom-color);
  font-size: 50px;
}


.login-text {
  margin: 0;
  padding: 20px;
  line-height: 1em;
  display: flex;
  float: right;
  color: var(--login-button-text-color);
  font-weight: bold;
}


/* Fade-in animations */
@keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .fade1, .fade2 {
    opacity: 0; /* Start hidden */
  }
  
  .fade1 {
    animation: fadeIn 2s ease-in-out 1s forwards; /* 1s delay, 2s duration */
  }
  
  .fade2 {
    animation: fadeIn 2s ease-in-out 3s forwards; /* 3s delay, 2s duration */
  }

.intro-page-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 50px;
}

.world-stage-image {
  width: 50%;
  height: auto;
}