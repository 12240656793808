.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-content {
  flex: 1;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

button {
  background-color: var(--main-content-button-background-color);
  color: var(--main-content-button-text-color);
  border: none;
  border-radius: 30px;
  padding: 15px 20px;
  font-size: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: var(--main-content-button-hover-background-color);
  color: var(--main-content-button-hover-text-color);
}

button:active {
  background-color: var(--main-content-button-active-background-color);
  color: var(--main-content-button-active-text-color);
}

.form-group-row {
  display: flex;
  flex-direction: row;
  gap: 25px;
}


.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

.form-group textarea {
  resize: vertical;
}

.form-group input[readonly] {
  cursor: not-allowed;
}

.color-inputs {
  display: flex;
  gap: 20px;
}

.color-input {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.color-input label {
  margin-bottom: 5px;
}

.color-input input[type="color"] {
  width: 40px;
  height: 40px;
  border: none;
  cursor: pointer;
}