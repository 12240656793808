.song_grid {
    grid-template-columns: .75fr 1fr 3fr 3fr 2fr 2fr 1fr;
    display: grid;
    gap: 10px;
    align-items: center;
    align-items: flex-start;
}

.grid_row {
    display: grid;
    grid-template-columns: .75fr 1fr 3fr 3fr 2fr 2fr 1fr;
    gap: 10px;
    grid-column: span 7;
    transition: background-color 0.2s ease;
    align-items: center;
    align-items: flex-start;
}

.grid_row_dragging {
    background-color: rgba(0, 0, 0, 0.1);
}

.grid_row_placeholder {
    background-color: rgba(0, 0, 0, 0.05);
    border: 2px dashed #ccc;
}

.contextMenuContainer {
    position: relative;
  }
  
  .contextMenu {
    position: absolute;
    top: 0;
    left: 100%; /* Position to the right of the button */
    background-color: var(--main-content-card-background-color);
    border: 1px solid #000;
    box-shadow: 0 2px 10px var(--shadow-color);
    border-radius: 4px;
    z-index: 1000;
    padding: 5px;
    width: 100px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;
  }

  .contextMenuIcon {
    margin-right: 8px;
  }
  
  .contextMenu button {
    display: block;
    width: 100%;
    padding: 8px 12px;
    background: none;
    border: none;
    text-align: left;
    cursor: pointer;
    font-size: 14px;
    color: var(--main-content-text-color);
  }
  
  .contextMenu button:hover {
    background-color: var(--main-content-button-background-color);
    color: var(--main-nav-button-hover-text-color);
  }

.header_container {
    display: flex;
    justify-content: flex-end; /* Move button to the right */
    margin-bottom: 25px;
}